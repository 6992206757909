import React from "react";
import Layout from "../../components/layout";
import courses from "../../images/courses.jpg";
const IndexPage = () => {

  return (
    <Layout>
      <hr />
      <div
        style={{
          color: "black",
          paddingLeft: "4rem",
          paddingRight: "4rem",
          textAlign: "center",
          marginTop: "4rem",
        }}
      >
        <div class="py-16">
          <div class="container m-auto px-6">
            <div class="lg:flex justify-between items-center">
              <div class="lg:w-6/12 lg:p-0 p-7">
                <h1 class="text-4xl font-bold leading-tight mb-5">
                  {" "}
                  Helping you sell courses
                </h1>
                <p class="text-xl">
                  {" "}
                  Whether you teach guitar, yoga or crochet – we’ll guide you
                  through setup and help you start teaching a scalable, online
                  course. Teach your clients ex
                </p>

                <div class="py-5">
                  <a
                    href="#"
                    class="text-white rounded-full py-2 px-5 text-lg font-semibold bg-purple-600 inline-block border border-purple-600 mr-3"
                  >
                    Try for free
                  </a>
                </div>
              </div>
              <div class="lg:w-5/12 order-2">
                <img
                  src={courses}
                  className="shadow-lg rounded max-w-full h-auto align-middle border-none"
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(191deg) rotateX(2deg) rotate(2deg)",
                  }}>
                </img>

              </div>
            </div>
          </div>
        </div>

        <div class="py-10 mb-10">
          <div>
            <section class="text-gray-200 bg-gray-900">
              <div class="max-w-6xl mx-auto px-5 py-24 ">
                <div class="text-center mb-20">
                  <h1 class=" title-font  mb-4 text-4xl font-extrabold leading-10 tracking-tight sm:text-5xl sm:leading-none md:text-6xl">
                    Course features
                  </h1>
                  <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                    Now anyone can build and sell courses, real fast. We’re the
                    platform that makes it all possible.
                  </p>
                  <div class="flex mt-6 justify-center">
                    <div class="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
                  </div>
                </div>
                <section class="flex flex-row flex-wrap mx-auto">
                  <div class="transition-all duration-150 flex w-full px-4 py-6 md:w-1/2 lg:w-1/3">
                    <div class="flex flex-col items-stretch min-h-full pb-4 mb-6 transition-all duration-150  rounded-lg shadow-lg hover:shadow-2xl">
                      <div class="flex flex-wrap items-center  px-4 py-1 text-center mx-auto">
                        <a href="#" class="hover:underline">
                          <h2 class="text-2xl font-bold tracking-normal text-white">
                            Quizzes
                          </h2>
                        </a>
                      </div>
                      <hr class="border-gray-300" />
                      <p class="flex flex-row flex-wrap w-full px-4 py-2 overflow-hidden text-sm text-justify text-white">
                        Create online assessments using the visual editor,
                        perform automated or manual grading and monitor your
                        learners progress over time
                      </p>
                    </div>
                  </div>

                  <div class="transition-all duration-150 flex w-full px-4 py-6 md:w-1/2 lg:w-1/3">
                    <div class="flex flex-col items-stretch min-h-full pb-4 mb-6 transition-all duration-150  rounded-lg shadow-lg hover:shadow-2xl">
                      <div class="flex flex-wrap items-center  px-4 py-1 text-center mx-auto">
                        <a href="#" class="hover:underline">
                          <h2 class="text-2xl font-bold tracking-normal text-white">
                            TRACK PROGRESS
                          </h2>
                        </a>
                      </div>
                      <hr class="border-gray-300" />
                      <p class="flex flex-row flex-wrap w-full px-4 py-2 overflow-hidden text-sm text-justify text-white">
                        Wish you had a crystal ball to see how well your
                        students are studying? Easily capture and manage student
                        progress individually and in the aggregate.Analyze
                        student progress and spot possible challenges in your
                        learning material. see exactly how much time your
                        students are spending on each section of your learning
                        material.
                      </p>
                    </div>
                  </div>

                  <div class="transition-all duration-150 flex w-full px-4 py-6 md:w-1/2 lg:w-1/3">
                    <div class="flex flex-col items-stretch min-h-full pb-4 mb-6 transition-all duration-150  rounded-lg shadow-lg hover:shadow-2xl">
                      <div class="flex flex-wrap items-center  px-4 py-1 text-center mx-auto">
                        <a href="#" class="hover:underline">
                          <h2 class="text-2xl font-bold tracking-normal text-white">
                            Certificates
                          </h2>
                        </a>
                      </div>
                      <hr class="border-gray-300" />
                      <p class="flex flex-row flex-wrap w-full px-4 py-2 overflow-hidden text-sm text-justify text-white">
                        It's hard to retain students when they can't see the
                        rewards of their efforts.Reward your student with a
                        digital certificates when they complete their online
                        courses.
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
